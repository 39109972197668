.container {
  padding: 1rem 2rem;
  background-image: url(../assets/logo-bg-mobile.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  text-align: right;
  min-height: 300px;

  @media (min-width: 700px) {
    background-image: url(../assets/logo-bg-tablet.png);
    min-height: 400px;
  }

  @media (min-width: 1280px) {
    padding: 3rem;
    background-image: url(../assets/logo-bg.png);
    background-size: contain;
    background-position: top right;
  }
}

.text {
  margin-left: auto;
  max-width: 360px;

  @media (min-width: 700px) {
    max-width: 550px;
  }
}
