.blackout {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}

.modalWindow {
  position: absolute;
  width: 90%;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 0 35px 5px rgba(0, 0, 0, 0.55);
  background-color: #d1d9dc;
  text-align: center;
  color: #073b4c;
  max-height: 90%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background-color: #d1d9dc;
    border-radius: 0 15px 15px 0;
    height: 100%;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #073b4c;
    border-radius: 15px;
  }
  scrollbar-color: #073b4c #d1d9dc;

  @media (min-width: 700px) {
    max-width: 650px;
  }
  @media (min-width: 1280px) {
    max-width: 850px;
  }
}

.closeBtn {
  display: block;
  margin-left: auto;
  margin-bottom: 1.5rem;
  top: 0;
  left: 100%;
  height: auto;
  width: 2rem;
  cursor: pointer;
  transition: 0.2s linear;

  &:hover {
    transform: scale(1.5) rotate(90deg);
  }
}

.modalMenu {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid #073b4c;
  margin-bottom: 2rem;

  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
}

.modalMenuItem {
  cursor: pointer;
  letter-spacing: 1.2px;
  line-height: 1.5em;
  font-weight: 700;
  padding: 0.25rem;
  border: 1px solid #073b4c;
  border-radius: 8px;
  display: grid;
  place-items: center;
  transition: 0.1s linear;

  &:hover {
    border-color: #ffffff;
    background-color: #ffffff;
  }
  &:active {
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.35);
  }
}

.filesWrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;

  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
    gap: 2.5rem;
  }
}

.fileButton {
  cursor: pointer;
  background-color: #073b4c;
  border-radius: 8px;
  padding: 0.55em;
  transition: 0.2s linear;
  display: grid;
  place-items: center;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(1);
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.75);
  }
}

.fileButtonLink {
  color: #ffffff;
  font-size: 1.15rem;
  letter-spacing: 1.1px;
  line-height: 1.5em;
  text-decoration: none;
}
