@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.mainContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (min-width: 1280px) {
    flex-direction: row-reverse;
    gap: 5rem;
  }
}

.marginBottom1rem {
  margin-bottom: 1rem;
}

.marginBottom2rem {
  margin-bottom: 2rem;
}

/*Typography*/

.largeHeader,
.mediumHeader {
  letter-spacing: 1.4px;
  font-weight: 400;
}

.largeHeader {
  font-size: 2rem;
  text-shadow: 0 0 3px #000000;

  @media (min-width: 700px) {
    font-size: 2.5rem;
  }
  @media (min-width: 1280px) {
    font-size: 3.25rem;
  }
}

.mediumHeader {
  font-size: 1.2rem;
  text-shadow: 0 0 1px #000000;

  @media (min-width: 700px) {
    font-size: 1.5rem;
  }
  @media (min-width: 1280px) {
    font-size: 2rem;
  }
}

.message {
  color: #3c536d;
  letter-spacing: 1.25px;
  position: relative;
  left: 1rem;
  top: 50%;
}
