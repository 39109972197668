.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 2rem;
  padding: 0 2rem;

  @media (min-width: 1280px) {
    padding: 0;
    margin-bottom: 0;
    align-items: flex-start;
    gap: 2.5rem;
    width: 100%;
  }
}

.menuItem {
  cursor: pointer;
  padding: 1em 1.5em;
  border-radius: 60px;
  letter-spacing: 1.25px;
  line-height: 1.5em;
  text-shadow: 0 0 1px #000000;
  text-align: center;

  @media (min-width: 1280px) {
    border-radius: 0 60px 60px 0;
    font-size: 1.2rem;
    text-align: left;

    &:hover {
      opacity: 0.7;
    }
    &:active {
      box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.75);
    }
  }
}
